import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import SwiperCore from 'swiper';
import './CompanyLogo.css'

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
const CompanyLogo = () => {
  const [serviceImages, setServiceImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  SwiperCore.use([Autoplay]);

  // Fetch data from the API
  useEffect(() => {
    setIsLoading(true);
    const fetchServiceImages = async () => {
      try {
        const response = await fetch('https://api.feelthewellness.in/feelapp/brands/'); // Replace with your API URL
        const data = await response.json();
        setServiceImages(data); // Assuming the API returns an array of images
      } catch (error) {
        console.error('Error fetching service images:', error);
      }
      finally
      {
        setIsLoading(false);
      }
    };

    fetchServiceImages();
  }, []);

  return (
  <>
  
  <div className="flex flex-col gap-3 bg-[#553833]">
    <div className='flex justify-center items-center mt-3'>
        <h4 className='text-white text-xl font-[LUXYNA]'>PRODUCTS WE USE</h4>
    </div>
      <div className="overflow-hidden mt-4 w-full md:mt-0 bg-[#F5F5F5] lg:p-14 md:p-8 p-5 pr-0 ">
      {isLoading ? (
            // Show skeletons while loading
            <div className="flex justify-center items-center gap-4 p-4">
              <Skeleton width={100} height={100} circle={true} />
              <Skeleton width={100} height={100} circle={true} />
              <Skeleton width={100} height={100} circle={true} />
            </div>
          ) : (

        <Swiper
          className="flex justify-center items-center overflow-hidden mx-auto w-fit max-w-[100%]"
          slidesPerView="auto"
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          speed={1000}
          lazy={true}
        >
       <div className='flex justify-center items-center'>
       {serviceImages.map((item, index) => (
            <SwiperSlide className="one-two flex flex-col items-center mr-0" key={index}>
               <img
                  src={item.logo}
                  alt={item.alt}
                  className="w-32 h-32 object-cover"
                />
            </SwiperSlide>
          ))}
       </div>
        </Swiper>
          )}
      </div>
    </div></>
  );
};

export default CompanyLogo;
