import React from "react";
import { FaYoutube, FaFacebookF, FaGoogle, FaInstagram } from "react-icons/fa";
import Footerlogo from "../../assets/1.svg";

const Footer = () => {
  return (
    <footer
      className="text-white py-10"
      style={{ background: "rgba(85, 56, 51, 1)" }}
    >
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-10  gap-8 text-center md:text-left">
        {/* Logo and Heading Section */}
        <div className="flex flex-col items-center md:col-span-4">
          <img
            src={Footerlogo}
            alt="Feel Wellness Logo"
            className="h-32 w-[14rem] md:h-48 md:w-56 filter invert"
            style={{ filter: "brightness(0) invert(1)" }} // Adjust filter properties as needed
          />
        </div>

        {/* Contact Info Section */}
        <div className="space-y-4 md:col-span-3">
          <h3 className="text-lg font-semibold uppercase">Contact Info</h3>
          <div className="flex flex-col gap-7">
            <div>
              <p className="text-sm" style={{ fontFamily: "Poppins" }}>
                148, Super Mall II, Above Gwalia, <br />
                Opp. Sankalp Restaurant, Infocity,
                <br />
                Gandhinagar, Gujarat 382007
              </p>
            </div>

            <div style={{ fontFamily: "Poppins" }}>
              <p className="text-sm font-semibold">+91 7405678955</p>
              <p className="text-sm font-semibold">+91 9099444567</p>
            </div>
            <div className="flex justify-center md:justify-start space-x-4 mt-4 text-2xl">
              <FaYoutube className="cursor-pointer hover:text-red-500 transition duration-200" 
                onClick={() => window.open("https://youtube.com/@feelthewellness1637?si=SgrsSaXOIGBMNutD")}
              />
              <FaFacebookF className="cursor-pointer hover:text-blue-500 transition duration-200" 
                onClick={() => window.open("https://www.facebook.com/share/jDRAz5T9rYuS5bwX/?mibextid=qi2Omg")}
              />
              <FaGoogle className="cursor-pointer hover:text-red-400 transition duration-200"
                onClick={() => window.open("https://g.co/kgs/nu9nU7j ")}
              />
              <FaInstagram className="cursor-pointer hover:text-pink-500 transition duration-200" 
                onClick={() => window.open("https://www.instagram.com/feelthewellness.in?igsh=MTY1M3pqczN4NHB3MA==")}
              />
            </div>
          </div>
        </div>

        {/* Opening Hours Section */}
        <div className="space-y-4 md:col-span-3">
          <h3 className="text-lg font-semibold uppercase">Opening Hours</h3>
          <div className="flex flex-col gap-5">
            <div>
              <p className="text-sm font-normal">Monday to Friday:</p>
              <p className="text-sm">11:00 AM – 09:00 PM</p>
            </div>
            <div>
              <p className="text-sm font-normal">Saturday / Sunday:</p>
              <p className="text-sm">10:00 AM – 09:00 PM</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
