// ContactUs.js
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  let emailREgx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const handleContactForm = async () => {
    if (!emailREgx.test(formData.email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    if (
      formData.email === "" ||
      formData.name === "" ||
      formData.message === ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    try {
      let url = "https://api.feelthewellness.in/feelapp/contact-us/";

      let response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          message: formData.message,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        toast.success("Message sent successfully");
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        toast.error("Something went wrong");
        return;
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    document.title = "Feel the wellness - Contact Us";
  }, []);

  return (
    <>
      <ToastContainer />
      {/* <div className="h-10 bg-[#54322C]"></div> */}
      <div className="bg-white">
        <div className="mx-auto pb-0">
          {/* Main Wrapper */}
          <div className="flex flex-col lg:flex-row">
            {/* Contact Form - 60% width */}
            <div className=" w-full lg:w-[65%] p-6 pt-[10px] bg-white">
              <h1
                className="text-[40px] font-serif text-center text-[#54322C]"
                style={{ fontFamily: "LUXYNA" }}
              >
                Contact Us
              </h1>

              <form className="space-y-4 p-4 md:p-6 lg:p-[7rem] lg:pt-4 lg:pb-[0.5rem]">
                <div>
                  <input
                    type="text"
                    placeholder="Name"
                    className="w-full px-4 py-2 rounded-md bg-[#F1F1F1] focus:outline-none focus:ring-2 focus:ring-gray-400 placeholder-[#54322C]"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </div>
                <div>
                  <input
                    type="email"
                    placeholder="Email"
                    className="w-full px-4 py-2 rounded-md bg-[#F1F1F1] focus:outline-none focus:ring-2 focus:ring-gray-400 placeholder-[#54322C]"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                <div>
                  <textarea
                    placeholder="Message"
                    rows="4"
                    className="w-full px-4 py-2 rounded-lg bg-[#F1F1F1] focus:outline-none focus:ring-2 focus:ring-gray-400 placeholder-[#54322C]"
                    value={formData.message}
                    onChange={(e) =>
                      setFormData({ ...formData, message: e.target.value })
                    }
                  />
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="px-6 py-2 bg-[#F1F1F1] text-[#54322C] rounded-md hover:bg-gray-300"
                    onClick={(e) => {
                      e.preventDefault();
                      handleContactForm();
                    }}
                  >
                    Send
                  </button>
                </div>
              </form>

              {/* Contact Information */}
              <div className="mt-10 mb-[10px] sm:mb-10 text-center md:text-left">
                {/* First and Second row in one row on mobile */}
                <div
                  className="flex flex-col sm:flex-row justify-center gap-6 md:gap-10 mt-8"
                  style={{ fontFamily: "Poppins" }}
                >
                  {/* Location */}
                  <div className="flex flex-col justify-center items-center sm:items-center text-[#54322C]">
                    <h3 className="font-medium">Location</h3>
                    <p className="text-sm text-center sm:text-center">
                      148, Super Mall II, Above Gwalia Sweet,
                      <br />
                      Opp. Sankalp Restaurant, Infocity,
                      <br />
                      Gandhinagar, Gujarat 382007
                    </p>
                  </div>

                  {/* Email */}
                  <div className="flex flex-col justify-center items-center sm:items-center text-[#54322C]">
                    <h3 className="font-medium">Email</h3>
                    <p className="text-sm">Contact@Feelthewellness.in</p>
                    <p className="text-sm">Info@Feelthewellness.in</p>
                  </div>
                </div>

                {/* Phone */}
                <div className="flex justify-center items-center flex-col mt-6 text-[#54322C]">
                  <h3 className="font-medium">Phone</h3>
                  <p className="text-sm">+91 9099444567 | +91 7405678955</p>
                </div>
              </div>
            </div>

            {/* Map - 40% width */}
            <div className="w-full  md:h-[50vh] lg:h-auto lg:w-[35%] lg:m-4 md:p-[25px]">
              {/* <iframe
                className="w-full h-64 md:h-full border-gray-300"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14686.198203604346!2d72.6184666!3d23.2156355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c2b0ecb156b4b%3A0xb16c5eb885aef6c4!2sGandhinagar%2C%20Gujarat%20382007!5e0!3m2!1sen!2sin!4v1600707153163!5m2!1sen!2sin"
                allowFullScreen=""
                loading="lazy"
              ></iframe> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.280379179109!2d72.6301964747754!3d23.19645050981809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c2a30ab68df9d%3A0x9bc21366ba0428dc!2sFeel%20The%20Wellness%20Salon%20And%20Unisex%20Spa!5e0!3m2!1sen!2sin!4v1729243568020!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                title="Feel The Wellness Salon And Unisex Spa"
                className="w-full h-64 md:h-full border-gray-300"

              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex flex-col justify-center items-center mt-5 p-6 sm:p-8 lg:p-10 gap-4"
        style={{ background: "rgba(85, 56, 51, 1)", fontWeight: "300" }}
      >
        <h2 className="font-[LUXYNA] text-white text-2xl sm:text-3xl lg:text-4xl text-center">
          Feel the wellness
        </h2>
        <p className="font-[LUXYNA] text-white text-center text-sm sm:text-base lg:text-lg max-w-full">
          We know how to unbrace all the muscles of your body, relieve back and
          joint pains and improve your well-being.
        </p>
      </div>
    </>
  );
};

export default ContactUs;
