import React from "react";
import Testimonials from "./Testimonials/Testimonials";
import CompanyLogo from "./Logo/ComanyLogo";
import Philosophy from "./Philosophy/Philosophy";
import Servcies from "./Services/Servcies";
import Hero from "./Hero/Hero";
import { useEffect, useState } from "react";
import Loader from "../loader/Loader";


const Home = () => {
  const [dataLoading, setDataLoading] = useState(true);

  const [data, setData] = useState([]);

  const fetchHeroSections = async () => {
    setDataLoading(true);
    try {
      let res = await fetch("https://api.feelthewellness.in/feelapp/hero-offers/");
      if (res.ok) {
        let data = await res.json();
        data.sort((a, b) => a.priority - b.priority);
        setData(data);
      } else {
        console.error(
          "Error: Unable to fetch data. Response status:",
          res.status
        );
      }
    } catch (error) {
      console.error("Error fetching Hero Offers:", error);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    fetchHeroSections();
  }, []);

  return dataLoading ? (
    <Loader />
  ) : (
    <>
      <Hero
        heroData={data}
      />
      <Philosophy />
      <Servcies />
      <CompanyLogo />
      <Testimonials />
    </>
  );
};

export default Home;
