import React, { useState } from 'react';
import Left from '../../../assets/Left.svg';
import Right from '../../../assets/Right.svg';
import { motion, AnimatePresence } from "framer-motion";

const Testimonial = () => {
  const testimonials = [
    {
      text: "Lovely experience here. The people are very hospitable and environment is very relaxing. I got a haircut from Smita, she did a fantastic job! Would definitely come back here again sometime.",
    },
    {
      text: "I am extremely happy with feel salon. I am glad that I came feel salon  for my services. Staff is extremely professional and warm. Atmosphere in salon is very warm. They make you feel very special. They listen to your requirement very carefully and understand what you want. They show you price before starting any services.",
    },
    {
      text: "The service provided is amazing, with all your needs being fulfilled. The staff is very professional and treat customers very well. Ambience of the place is calming and classy too. I have been here multiple times and left very happy with the service, be it a spa, haircut or facial. ",
    },
    {
      text: "Staff behaviour very good and cooperative. Each services are good in price range. Thanks Smita for hair cut service. I recommend to all to go at Feel the Wellness and feel the experience. ",
    },
    {
      text: "I recently visited Feel the Wellness Spa and Salon and had an amazing experience! The staff were incredibly friendly and attentive, and the atmosphere was so relaxing. My massage was heavenly, and the facial left my skin glowing. I highly recommend this spa for anyone in need of some self-care and relaxation!😍",
    },
    {
      text: "Very friendly atmosphere. Excellent and cooperative staff. Varsha mam and Smita have attended me. They both do the work with full dedication. I really feel pampered and rejuvenated. Wonderful experience always. 😊 👍",
    },
    {
      text: "The way they did hairstyling is good, they have done according to our instructions . It's is a good place to go for regular haircut.",
    },
    {
      text: "I love it, I got my hairs proper shape what I wished for, and with complimentary suggest for hair health, everything were worth doing it.",
    },
    {
      text: "First time visiting and it was great ! My stylist  Mr Siraj provided amazing service, and put in loads of time with my excessive amount of hair..I loved the colour, the much-needed trim and bouncy blow-out..This place is worth the splurge to look and feel wonderful.. coming back definitely 🌼✌️",
    },
    {
      text: "I recently visited Feel the Wellness, a hair and spa salon, for a haircut and hair spa. The experience was exceptional. The stylist gave me a fantastic haircut, paying attention to detail. The hair spa left my hair nourished and refreshed.",
    },
    {
      text: "Had a great experience.....and so happy to get a perfect haircut as I wanted by my stylist Siraj. They were very professional and gave me so much knowledge about skin and hair wellness."
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to go to the previous testimonial
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  // Function to go to the next testimonial
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <section className="bg-[#553833] py-12">
       
      <div className="max-w-3xl mx-auto text-center md:p-10 lg:p-0" 
       
      >
        {/* Header */}
        <h2 className="text-3xl font-serif text-white tracking-wider" style={{fontFamily:'LUXYNA'}}>TESTIMONIALS</h2>
        <p className="text-white text-sm mt-2 mb-8"  style={{fontFamily:'Poppins'}}>Judge us based on our reviews</p>

        {/* Testimonial Card */}
        <AnimatePresence mode="wait">
        <motion.div 
        initial={{ opacity: 0, x: -20 }}
        transition={{ duration: 0.9, delay: 0.5 }}
        whileInView={{ opacity: 1, y: 0 , x: 0}}
       className="relative m-5 md:m-0 bg-[#F5F5F5] rounded-[50px] p-[55px] md:p-[85px] shadow-lg ">
          {/* Quote marks */}
          <div className="absolute top-[-30px] left-[40px] text-[#FCF1FD] text-9xl font-serif" style={{filter:'drop-shadow(0 25px 25px rgb(0 0 0 / 4.15))'}}>
            &#8220;
          </div>
          <div className="absolute top-[-30px] right-[40px] text-[#FCF1FD] text-9xl font-serif" style={{filter:'drop-shadow(0 25px 25px rgb(0 0 0 / 4.15))'}}>
            &#8221;
          </div>

          {/* Testimonial Text */}
          <p className="text-[#52312E] text-sm md:text-base"  style={{fontFamily:'Poppins'}}>
            {testimonials[currentIndex].text}
          </p>

          {/* Arrows for navigation */}
          <div className="absolute top-1/2 left-5 md:left-10 transform -translate-y-1/2 -translate-x-1/2 z-10">
            <button
              onClick={handlePrev}
              className="flex items-center justify-center text-[#52312E] text-2xl bg-transparent rounded-full p-2 hover:bg-gray-200 transition duration-200"
            >
             <img src={Right} alt="right" />
            </button>
          </div>
          <div className="absolute top-1/2 right-5 md:right-10 transform -translate-y-1/2 translate-x-1/2 z-10">
            <button
              onClick={handleNext}
              className="flex items-center justify-center text-[#52312E] text-2xl bg-transparent rounded-full p-2 hover:bg-gray-200 transition duration-200"
            >
                  <img src={Left} alt="left" />
             
            </button>
          </div>
        </motion.div>
      </AnimatePresence>
      </div>
    </section>
  );
};

export default Testimonial;
