import React from 'react';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loader from "../loader/Loader";

const BlogPost = () => {


  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://api.feelthewellness.in/feelapp/blogs/?slug=${slug}`);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        let responseData = await response.json();

        if (responseData && responseData.length > 0) {
          setPost(responseData[0]); 
        } else {
          setError('Blog post not found.');
        }
      } catch (err) {
        setError('Error loading blog post. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();

    document.title = "Feel the wellness - Blog Post";

  }, [slug]);

  return (
    
      loading ? (
    <Loader />
  ) : (
    <>
    <div className='bg-[#553833]'>
    <div className="max-w-4xl mx-auto p-5 bg-white">
    <div className="relative mb-5">
      {/* Uncomment this if you need the date */}
      {/* <div className="absolute left-2 top-2 bg-white/80 p-2 font-bold rounded-md shadow-sm text-center text-gray-900">
        {new Date(post.created_at).toLocaleDateString()}
      </div> */}
      <img src={post.image_blog} alt={post.title} className="w-full h-auto object-cover aspect-[16/9] rounded-md shadow-lg" />
    </div>
    <div className="mt-5 bg-transparent p-5 text-white rounded-md shadow-md">
      <p className="text-sm text-gray-400 mb-2">Published on - {new Date(post.created_at).toLocaleDateString()}</p>
      <h1 className="text-4xl font-bold mb-5 text-[#553833]">{post.title}</h1>
      <p className="my-4 text-black">{post.content}</p>
      {post.black && (
        <blockquote className="my-5 px-5 py-3 bg-gray-300 border-l-4 border-gray-300 text-gray-600 rounded-md shadow-sm">
          <p className='text-gray-900'>{post.black}</p>
        </blockquote>
      )}
      <p className="mt-5 text-lg text-black">{post.meta_description}</p>
      <img src={post.image_blog} alt={post.title} className="w-full h-auto object-cover mt-5 aspect-[16/9] rounded-md shadow-lg" />
      <p className="my-4 text-black">{post.content}</p>
    </div>
  </div>
  </div>
  </>
  )
  );
};

export default BlogPost;
