import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../loader/Loader";

const MakeupServices = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [postsToShow, setPostsToShow] = useState(9);
  const [loading, setLoading] = useState(true);

  const fetchBlogPosts = async (slug) => {
    setLoading(true);
    try {
      const response = await fetch(`https://api.feelthewellness.in/feelapp/blogs/`);
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      console.log("Fetched blog posts:", data);

      if (Array.isArray(data) && data.length > 0) {
        setBlogPosts(data);
        setCurrentPosts(data.slice(0, postsToShow));
      } else {
        setBlogPosts([]);
        setCurrentPosts([]);
      }
    } catch (error) {
      console.error("Error fetching blog posts:", error);
      setBlogPosts([]);
      setCurrentPosts([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogPosts();

    document.title = "Feel the wellness - Blog";
  }, []);

  useEffect(() => {
    setCurrentPosts(blogPosts.slice(0, postsToShow));
  }, [blogPosts, postsToShow]);

  const showMorePosts = () => {
    setPostsToShow((prev) => prev + 9);
  };

  const showLessPosts = () => {
    setPostsToShow(9);
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
     <h1 className="text-[60px] pt-3 pb-3 lg:pt-5 lg:pb-5 font-serif text-center text-[#54322C]" style={{fontFamily:'LUXYNA'}}>Blogs</h1>

      <div className="bg-[#553833]">
     

        <div
          className="flex justify-center gap-[16px] md:gap-[20px] lg:gap-[15px] p-4 md:p-5 lg:p-10 flex-wrap w-full"
        >
          {currentPosts.length > 0 ? (
            currentPosts.map((post) => (
              <div
                className="bg-white w-full md:w-[calc(50%-10px)]  lg:w-[calc(33.33%-10px)] overflow-hidden transition-transform duration-300 ease-in-out shadow-lg rounded-md"
                key={post.id}
              >
                <Link
                  to={`/blog/${post.slug}`}
                  className="relative w-full h-56 rounded-md"
                >
                  <div className="relative w-full h-[10rem] md:h-56 rounded-md">
                    <img
                      src={post.image_blog}
                      alt={post.title}
                      className="w-full h-full object-cover block cursor-pointer"
                    />
                  </div>
                </Link>
                <div className="p-4">
                  <p className="text-2xl text-[#553833]">{post.title}</p>
                  <p className="text-[#969696] text-lx">
                    {formatDate(post.created_at)}
                  </p>
                  <h3 className="text-sm">{truncateText(post.content, 120)}</h3>
                </div>
              </div>
            ))
          ) : (
            <p className="text-white">No blog posts available.</p>
          )}
        </div>

        <div className="bg-[#553833] flex justify-center items-center gap-2 mt-5">
          {postsToShow < blogPosts.length && (
            <button
              className="py-3 px-6 bg-white text-black border border-gray-400 cursor-pointer transition-all duration-500 mb-5"
              onClick={showMorePosts}
            >
              Show More
            </button>
          )}
          {postsToShow > 9 && (
            <button
              className="py-3 px-6 bg-white text-black border border-gray-400 cursor-pointer transition-all duration-500 mb-5"
              onClick={showLessPosts}
            >
              Show Less
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default MakeupServices;
