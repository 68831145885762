import React from "react";
import "./loader.css";

const Loader = () => {
  return (
    <div id="preloader"  
    >
        <div class="preloader" style={{ opacity: 0.609072 }}>
            <span></span>
            <span></span>
        </div>
    </div>
  );
};

export default Loader;