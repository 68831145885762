import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

// Provide AuthContext to your app
export const AuthProvider = ({ children }) => {

    const [cartCount, setCartCount] = useState(1);

    useEffect(() => {

        let val = JSON.parse(localStorage.getItem('bookedServiceData'))?.length || 0;
        
        setCartCount(val);

    }, [cartCount]);



    return (
        <AuthContext.Provider value={{ cartCount ,setCartCount }}>
            {children}
        </AuthContext.Provider>
    );
};