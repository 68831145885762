import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/1.svg";
import Vector from "../../assets/Vector.svg";
import Cart from "../../assets/cart.svg";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';

const Navbar = () => {

  const { cartCount , setCartCount } = useContext(AuthContext);

  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileServicesOpen, setIsMobileServicesOpen] = useState(false);
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState([]);


  const getCategoryData = async () => {
    let url = "https://api.feelthewellness.in/feelapp/categories/?status=active";

    try {
      let res = await fetch(url);

      if (res.ok) {
        let data = await res.json();
        setCategoryData(data);
      } else {
        console.log("Error in response");
      }
    } catch (error) {
      console.log("Error in fetching data");
    }
  };

  useEffect(() => {
    getCategoryData();
  }, []);

  //This Is A Mobile SideShow For Services
  const MobileServicesPage = () => (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ type: "tween", duration: 0.5 }}
      className="fixed top-0 left-0 right-0 bottom-0 bg-white z-[10000000] font-[Bulter] overflow-y-auto"
    >
      <div className="flex justify-between items-center p-4 border-b">
        <button
          onClick={() => setIsMobileServicesOpen(false)}
          className="text-gray-700hover:font-[500]"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            ></path>
          </svg>
        </button>
        <h2 className="text-xl font-bold">Services</h2>
        <button
          onClick={() => {
            setIsMobileServicesOpen(false);
            setIsMobileMenuOpen(false);
          }}
          className="text-gray-700hover:font-[500]"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div className="p-4">
        {categoryData?.map((item, index) => (
          <Link
            key={index}
            to={`/service/${item?.slug}`}
            className="block text-gray-700hover:font-[500] py-2 px-3 "
          >
            {item?.name}
          </Link>
        ))}
      </div>
    </motion.div>
  );

  // disable scroll when mobile menu is open

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMobileMenuOpen]);

  // when path changes, close mobile menu
  const path = window.location.pathname;
  useEffect(() => {
    setIsMobileMenuOpen(false);
    setIsMobileServicesOpen(false);    
  }
  , [path]);


  return (
    <nav className="bg-white shadow-none lg:shadow-sm  px-3 md:px-3  lg:px-6 py-2 md:py-5 lg:py-10 flex justify-between items-center">
      <div className="hidden lg:flex  w-20">{/* blank div     */}</div>

      <div className="flex items-center justify-center">
        {/* Left part: Links */}
        <div className="hidden lg:flex gap-4 items-center font-[Bulter] font-[300]">
          <Link to="/" className="py-2 px-3 hover:font-[500]" >
            HOME
          </Link>
          <Link to={'/About'} className="py-2 px-3 hover:font-[500]">
            ABOUT US
          </Link>
          <div
            className="relative"
            onMouseEnter={() => setIsServicesOpen(true)}
            onMouseLeave={() => setIsServicesOpen(false)}
          >
            <a href="#" className=" py-7 px-3hover:font-[500]">
              SERVICES
            </a>
            {isServicesOpen && (
              <div className="absolute left-[-100px] mt-4 py-4 borde rounded-xl bg-[#FFF5EA]" style={{fontFamily:"Poppins",zIndex:"100000"}}>
                <div className="flex space-x-4 px-6">
                  {categoryData?.map((item, index) => (
                    <Link
                      key={index}
                      to={`/service/${item?.slug}`}
                      className="hover:font-[500] whitespace-nowrap min-w-max"
                    >
                      {item?.name}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Center Logo */}
        <div className="hidden lg:flex justify-center items-center mx-0 lg:mx-8">
          <img src={logo} alt="Logo" className="h-12 lg:h-16" 
            onClick={() => {
              navigate("/");
            }}
          />
        </div>

        {/* Right part: Links */}
        <div className=" hidden lg:flex gap-4" style={{fontFamily:'Bulter' , fontWeight : 300}} >
          <Link to="/gallery" className="py-2 px-3 hover:font-[500]">
            GALLERY
          </Link>
          <Link to={'/Blog'} className="py-2 px-3 hover:font-[500]">
            BLOG
          </Link>
          <Link to={'/Contect'} className="py-2 px-3 hover:font-[500]">
            CONTACTS
          </Link>
        </div>
      </div>

      <div className="hidden lg:flex gap-4 justify-center w-20">
        <button className="text-gray-700hover:font-[500] relative"
          onClick={() => {
            navigate("/cart");
          }}
        > 
          <img src={Cart} alt="Cart" className="h-5" />
          {cartCount > 0 && (
                <div
                  className=""
                  style={{
                    position: "absolute",
                    top: "-6px",
                    right: "-8px",
                    backgroundColor: "green",
                    color: "white",
                    borderRadius: "50%",
                    width: "16px",
                    height: "16px",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "10px",
                    display: "flex",
                  }}
                >
                  {cartCount}
                </div>
              )}
        </button>
        {/* <button className="text-gray-700hover:font-[500]">
          <img src={Vector} alt="Vector" className="h-3" />
        </button> */}

      </div>

      {/* Mobile menu icon */}
      <div className="lg:hidden flex justify-between items-center gap-4 text-center w-full">

      <button
          className="text-gray-700hover:font-[500]"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          {/* Conditionally render the hamburger or X icon */}
          {isMobileMenuOpen ? (
        <AiOutlineClose className="w-6 h-6" />
      ) : (
        <AiOutlineMenu className="w-6 h-5" />
      )}
        </button>

        <div className="flex lg:hidden justify-center items-center mx-0 lg:mx-8">
          <img src={logo} alt="Logo" className="h-[4rem] lg:h-16" 
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <button className="text-gray-700 hover:font-[500] relative"
          onClick={() => {
            navigate("/cart");
          }}
        > 
          <img src={Cart} alt="Cart" className="h-5" />
          {cartCount > 0 && (
                <div
                  className=""
                  style={{
                    position: "absolute",
                    top: "-6px",
                    right: "-8px",
                    backgroundColor: "green",
                    color: "white",
                    borderRadius: "50%",
                    width: "16px",
                    height: "16px",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "10px",
                    display: "flex",
                  }}
                >
                  {cartCount}
                </div>
              )}
        </button>
       
      </div>

      {/* Mobile menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
              when: "beforeChildren",
              staggerChildren: 0.2,
            }}
            className="lg:hidden absolute top-20 left-0 pt-0 right-0 bg-white shadow-lg flex flex-col items-start p-4 h-[calc(100%-80px)] overflow-hidden" 
            style={
              {
                zIndex:'1000000',
                fontFamily:"Bulter",
                fontWeight:300
              }
            }
          >
            <Link to="/" className="text-gray-700 py-2 px-3hover:font-[500] font-light">
              HOME
            </Link>
            <Link to={'/About'} className="text-gray-700 py-2 px-3hover:font-[500]">
              ABOUT US
            </Link>
            <a
              href="#"
              className="text-gray-700 py-2 px-3hover:font-[500]"
              onClick={() => {
                setIsMobileServicesOpen(true);
              }}
            >
              SERVICES
            </a>
            <Link to="/gallery" className="text-gray-700 py-2 px-3hover:font-[500]">
              GALLERY
            </Link>
            <Link to={'/Blog'} className="text-gray-700 py-2 px-3hover:font-[500]">
              BLOG
            </Link>
            <Link to={'/Contect'} className="text-gray-700 py-2 px-3hover:font-[500]">
              CONTACTS
            </Link>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Mobile services page */}
      <AnimatePresence>
        {isMobileServicesOpen && <MobileServicesPage />}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;
