import React from "react";
import { useState, useEffect } from "react";
import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Hero = ({heroData}) => {
  const [data, setData] = useState(heroData);

  return (
    <div className="relative">
      <Swiper
        modules={[Navigation, Scrollbar, A11y, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{ clickable: true }}
        effect="fade"
      >
        {data?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="w-full h-full">
              {window.innerWidth > 768 ? (
                <img src={item?.image} alt="" className="w-full h-auto"/>
              ) : (
                <img
                  src={item?.mobile_image}
                  alt=""
                  className="w-full h-[54vh]"
                />
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    
    </div>
  );
};

export default Hero;