// Counting.js
import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';

const Counting = ({ end, duration, label }) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const countRef = useRef(null);


  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      {
        threshold: 0.5, // Adjust this threshold as needed
      }
    );

    if (countRef.current) {
      observer.observe(countRef.current);
    }

    return () => {
      if (countRef.current) {
        observer.unobserve(countRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    let start = 0;
    const endValue = parseInt(end, 10); // Convert to number
    const range = endValue - start;
    const increment = endValue / (duration / 50); // Increment per frame

    const timer = setInterval(() => {
      start += increment;
      if (start >= endValue) {
        setCount(endValue);
        clearInterval(timer);
      } else {
        setCount(Math.round(start));
      }
    }, 50);

    return () => clearInterval(timer);
  }, [end, duration, isVisible]);


  return (
<div className="text-3xl font-bold mb-2" ref={countRef}>
<motion.h5
  className="stat-number"
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
  transition={{ duration: 1.5 }}
>
  {count}+
</motion.h5>
<p className="stat-label">{label}</p>
</div>
  );
};

export default Counting;
