import React from "react";
import { Autoplay } from "swiper/modules";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "./services.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import SwiperCore from "swiper";
import Services2 from "../../../assets/Services1.png";
import Services1 from "../../../assets/Services2.png";
import hair from "../../../assets/images/Hair.jpg";
import Massage from "../../../assets/images/Massage.jpeg";
import As from "../../../assets/images/As.jpg";
import Skin from "../../../assets/images/skin.jpg";
import Package from "../../../assets/images/Package.jpeg";

import { Link, useNavigate } from "react-router-dom";

const Services = () => {
  SwiperCore.use([Autoplay]);

  const navigate = useNavigate();

  //   Hair: Revitalize your look with expert cuts and vibrant color treatments, leaving your hair healthy and full of life.
  // Nails: Pamper yourself with flawless manicures and pedicures for a lasting shine.
  // Massage: Rejuvenate your body and melt away stress with our soothing massage services.
  // Skin: Refresh and glow with personalized facials and skin treatments.
  // Packages: Indulge in our beauty services from hair to nails, ensuring you look and feel your best.

  const services = [
    {
      title: "Skin",
      description:
        "Refresh and glow with personalized facials and skin treatments.",
      img: Skin,
      link: "/service/skin",
      buttonLabel: "Book Now",
    },
    {
      title: "Hair",
      description:
        "Revitalize your look with expert cuts and vibrant color, leaving your hair healthy and vibrant.",
      img: hair,
      link: "/service/hair",
      buttonLabel: "Book Now",
    },
    {
      title: "International Massage",
      description:
        "Rejuvenate your body and melt away stress with our soothing massage services.",
      img: Massage,
      link: "/service/international-massage",
      buttonLabel: "Book Now",
    },
    {
      title: "Nail Art",
      description:
        "Pamper yourself with flawless manicures and pedicures for a lasting shine.",
      img: Services2,
      link: "/service/nail-art",
      buttonLabel: "Book Now",
    },
    {
      title: "Aesthetic Skin Care",
      description:
        "Aesthetic skin care enhances your skin's beauty and health with a personalized touch.",
      img: As,
      link: "/service/aesthetic-skin-care",
      buttonLabel: "Book Now",
    },
    {
      title: "Packages",
      description:
        "Indulge in our beauty services from hair to nails, ensuring you look and feel your best.",
      img: Package,
      link: "/service/packages",
      buttonLabel: "Book Now",
    },
    // Add more services as needed
  ];

  return (
    <>
      <div className="bg-[#553833] h-44 md:h-56">
        <div className="text-center text-4xl pb-14 md:pb-14">
          <h2
            className="text-white p-10"
            style={{ fontFamily: "LUXYNA", letterSpacing: "0.1em" }}
          >
            SERVICES
          </h2>
        </div>
      </div>

      <section className="relative bg-[#553833] text-white py-8 md:py-12">
        {/* Swiper positioned relative to section */}
        <div className="absolute top-[-2rem] md:top-[-8rem] lg:top-[-4rem] xl:top-[-8rem] left-1/2 transform -translate-x-1/2 z-10 w-full">
          <Swiper
            slidesPerView={window.innerWidth > 1024 ? 2 : 1}
            loop={true}
            autoplay={{
              disableOnInteraction: false,
            }}
            pauseOnMouseEnter={true}
            waitForTransition={true}
            speed={3000} // 3 seconds per swipe
            watchSlidesProgress={true}
            className="w-full"
            // pagination={{ clickable: true }}
            // modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          >
            {services.map((service, index) => (
              <SwiperSlide
                key={index}
                className="Services-Swiper-content max-w-[1024px]"
              >
                <div className="flex flex-row gap-6 md:gap-10 rounded-lg p-6 md:p-8 text-gray-800 xl:ml-[40px]">
                  <div className="flex flex-col justify-center items-center gap-5">
                    <div className="rounded-md shadow-md w-32 h-44 md:w-56 md:h-72 lg:h-60 lg:w-44  xl:w-56 xl:h-72 bg-white object-cover">
                      <img
                        src={service.img}
                        alt=""
                        className=" w-full h-full object-cover rounded-md"
                      />
                    </div>
                    <button
                      className="bg-[#553833] text-white px-6 md:px-10 flex justify-center items-center py-2 rounded-full w-max"
                      style={{ fontFamily: "Poppins" }}
                    >
                      <Link to={service.link}>{service.buttonLabel}</Link>
                    </button>
                  </div>
                  <div className="flex flex-col justify-end max-w-full md:mb-8">
                    <h3
                      className="text-lg md:text-3xl font-bold mb-3 md:mb-4 text-[#553833]"
                      style={{ fontFamily: "LUXYNA" }}
                    >
                      {service.title}
                    </h3>
                    <p
                      className="mb-4 md:mb-6 text-sm  text-[#553833]"
                      style={{ fontFamily: "Poppins" }}
                    >
                      {service.description}
                    </p>
                    <div className="flex space-x-4 text-sm">
                      <a
                        href={service.link}
                        className="text-[#553833]"
                        style={{ fontFamily: "Poppins" }}
                      >
                        explore more &rarr;
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="bg-white h-56 md:h-64 lg:h-60 xl:h-64 relative z-0"></div>

        <div className="flex justify-center mt-12">
          <p
            className="md:text-lg text-center text-[13px] px-4"
            style={{ fontFamily: "Poppins" }}
          >
            <span
              onClick={() => {
                navigate("/service/hair");
              }}
              className="cursor-pointer"
            >
              {" "}
              SKIN{" "}
            </span>{" "}
            |
            <span
              onClick={() => {
                navigate("/service/hair");
              }}
              className="cursor-pointer"
            >
              {" "}
              HAIR{" "}
            </span>{" "}
            |
            <span
              onClick={() => {
                navigate("/service/international-massage");
              }}
            >
              {" "}
              INTERNATIONAL MASSAGE{" "}
            </span>{" "}
            |
            <span
              onClick={() => {
                navigate("/service/nail-art");
              }}
              className="cursor-pointer"
            >
              {" "}
              NAIL ART{" "}
            </span>{" "}
            |
            <span
              onClick={() => {
                navigate("/service/aesthetic-skin-care");
              }}
              className="cursor-pointer"
            >
              {" "}
              AESTHETIC SKIN CARE{" "}
            </span>{" "}
            |
            <span
              onClick={() => {
                navigate("/service/packages");
              }}
              className="cursor-pointer"
            >
              {" "}
              PACKAGES{" "}
            </span>
          </p>
        </div>
      </section>

      <div></div>
    </>
  );
};

export default Services;
