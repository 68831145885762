import React, { useContext, useEffect, useState } from "react";
import img1 from "../../assets/images/../../assets/images/Feel - 1/6.png";
import img2 from "../../assets/images/../../assets/images/Feel - 2/6.png";
import img3 from "../../assets/images/../../assets/images/Feel - 3/6.png";
import img4 from "../../assets/images/../../assets/images/Feel - 4/6.png";
import img5 from "../../assets/images/../../assets/images/Feel - 5/6.png";

import Makeup1 from '../../assets/Feel - 1 .png'
import Makeup2 from '../../assets/Feel - 2.png'
import Makeup3 from '../../assets/Feel - 3.png'
import Makeup4 from '../../assets/Feel - 4.png'
import Makeup5 from '../../assets/Feel - 5.png'

import hair1 from '../../assets/images/Feel - 1/1.png'
import hair2 from '../../assets/images/Feel - 2/1.png'
import hair3 from '../../assets/images/Feel - 3/1.png'
import hair4 from '../../assets/images/Feel - 4/1.png'
import hair5 from '../../assets/images/Feel - 5/1.png'


import skin1 from '../../assets/images/Feel - 1/4.png'
import skin2 from '../../assets/images/Feel - 2/4.png'
import skin3 from '../../assets/images/Feel - 3/4.png'
import skin4 from '../../assets/images/Feel - 4/4.png'
import skin5 from '../../assets/images/Feel - 5/4.png'

import nail1 from '../../assets/images/Feel - 1/2.png'
import nail2 from '../../assets/images/Feel - 2/2.png'
import nail3 from '../../assets/images/Feel - 3/2.png'
import nail4 from '../../assets/images/Feel - 4/2.png'
import nail5 from '../../assets/images/Feel - 5/2.png'


import massage24 from '../../assets/images/Feel - 1/3.png'
import massage25 from '../../assets/images/Feel - 2/3.png'
import massage26 from '../../assets/images/Feel - 3/3.png'
import massage27 from '../../assets/images/Feel - 4/3.png'
import massage28 from '../../assets/images/Feel - 5/3.png'


import skinCare1 from '../../assets/images/Feel - 1/5.png';
import skinCare2 from '../../assets/images/Feel - 2/5.png';
import skinCare3 from '../../assets/images/Feel - 3/5.png';
import skinCare4 from '../../assets/images/Feel - 4/5.png';
import skinCare5 from '../../assets/images/Feel - 5/5.png';


import FellText from "../../assets/images/FELL-text.png";

import { useParams } from "react-router-dom";
import { AuthContext } from "../../AuthContext";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../loader/Loader";

const ServicePage = () => {

  const imagesMap = {
    "skin": [skin1 , skin2 , skin3, skin4, skin5],
    "makeup": [Makeup1, Makeup2, Makeup3, Makeup4, Makeup5],
    "international-massage": [massage24, massage25 ,massage26 ,massage27,massage28],
    "hair": [hair1, hair2, hair3, hair4, hair5],
    "nail-art": [nail1, nail2, nail3, nail4, nail5],
    "packages": [img1, img2, img3, img4, img5],
    "aesthetic-skin-care": [skinCare1,skinCare2,skinCare3,skinCare4,skinCare5],

  }

  const params = useParams();
  const { cartCount, setCartCount } = useContext(AuthContext);

  const [serviceData, setServiceData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [childCategoryData, setChildCategoryData] = useState([]);
  const [selectedChildCategory, setSelectedChildCategory] = useState(null);
  const [filteredServices, setFilteredServices] = useState([]);
  const [bookedServiceData, setBookedServiceData] = useState([]);
  const [openServiceBox, setOpenServiceBox] = useState(false);
  const [childCategoryApiData, setChildCategoryApiData] = useState([]);
  const [initialDataLoading, setInitialDataLoading] = useState(true);
  const [loading, setLoading] = useState({
    childCat: true,
    subCat: true,
    service: true,
  });
  const [showDescription, setShowDescription] = useState([]);

  useEffect(() => {
    const bookedServices = localStorage.getItem("bookedServiceData");
    if (bookedServices) {
      setBookedServiceData(JSON.parse(bookedServices));
    }

    document.title = "Feel the wellness - Services";

  }, []);

  useEffect(() => {
    localStorage.setItem(
      "bookedServiceData",
      JSON.stringify(bookedServiceData)
    );
    setCartCount(bookedServiceData.length);
  }, [bookedServiceData, setCartCount]);

  const fetchServiceData = async (slug) => {
    setInitialDataLoading(true);
    setLoading((prev) => ({ ...prev, service: true }));

    try {
      let response = await fetch(
        `https://api.feelthewellness.in/feelapp/servicesuser/?category_slug=${slug}`
      );

      if (response.ok) {
        let data = await response.json();
        data.sort((a, b) => Number(a.priority) - Number(b.priority));
        setServiceData(data);
      } else {
        console.log("Error fetching service data");
        setServiceData([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setInitialDataLoading(false);
      setLoading((prev) => ({ ...prev, service: false }));
    }
  };

  const fetchSubCategoryData = async (slug) => {
    setLoading((prev) => ({ ...prev, subCat: true }));

    try {
      let response = await fetch(
        `https://api.feelthewellness.in/feelapp/subcategory/?category_slug=${slug}`
      );

      if (response.ok) {
        let data = await response.json();
        data.sort((a, b) => Number(a.priority) - Number(b.priority));
        setSubCategoryData(data);
        setSelectedSubCategory(data[0]);
      } else {
        console.log("Error fetching sub-category data");
        setSubCategoryData([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({ ...prev, subCat: false }));
    }
  };

  const fetchChildCategoryData = async (slug) => {
    setLoading((prev) => ({ ...prev, childCat: true }));

    try {
      let response = await fetch(
        `https://api.feelthewellness.in/feelapp/childcategory/?category_slug=${slug}`
      );

      if (response.ok) {
        let data = await response.json();
        data.sort((a, b) => Number(a.priority) - Number(b.priority));
        setChildCategoryApiData(data);
      } else {
        console.log("Error fetching child-category data");
        setChildCategoryApiData([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({ ...prev, childCat: false }));
    }
  };

  useEffect(() => {
    if (params?.slug) {
      fetchServiceData(params?.slug);
      fetchSubCategoryData(params?.slug);
      fetchChildCategoryData(params?.slug);
    }
  }, [params?.slug]);

  useEffect(() => {
    if (loading.childCat || loading.subCat || loading.service) return;

    if (selectedSubCategory) {
      const childcategoryDList = childCategoryApiData?.filter(
        (child) => child?.subcategory === selectedSubCategory?.id
      );

      if (childcategoryDList.length === 0) {
        setChildCategoryData([]);
        setSelectedChildCategory(null);
        const filteredServices = serviceData.filter(
          (service) => service?.subcategory === selectedSubCategory?.id
        );
        setFilteredServices(filteredServices);
      } else {
        setChildCategoryData(childcategoryDList);
        setSelectedChildCategory(childcategoryDList[0]);
      }
    } else {
      setChildCategoryData([]);
      setSelectedChildCategory(null);
    }
  }, [
    selectedSubCategory,
    childCategoryApiData,
    serviceData,
    loading.childCat,
    loading.subCat,
    loading.service,
  ]);

  useEffect(() => {
    if (loading.childCat || loading.subCat || loading.service) return;

    if (selectedChildCategory) {
      const filteredServices = serviceData.filter(
        (service) => service?.childcategory === selectedChildCategory?.id
      );
      setFilteredServices(filteredServices);
    } else {
      const filteredServices = serviceData.filter(
        (service) => service?.subcategory === selectedSubCategory?.id
      );
      setFilteredServices(filteredServices);
    }
  }, [
    selectedChildCategory,
    serviceData,
    childCategoryApiData,
    loading.childCat,
    loading.subCat,
    loading.service,
  ]);

  const selectedImages = imagesMap[params.slug] || "default";

  return (
    loading.childCat || loading.subCat || loading.service ) ? (
      <>
        <Loader />
      </>
    ) : (
    <>
      <ToastContainer />

      <div className="bg-[#553833]">
        <h2 className=" bg-white uppercase text-2xl md:text-3xl lg:leading-[60px] lg:text-[48px] py-6 text-center font-medium">
          {selectedSubCategory?.category_details?.name}
        </h2>
        {/* <div className="w-full h-auto grid grid-cols-10 bg-[#553833] px-4 py-5 gap-4 lg:px-14 lg:py-10 lg:gap-10">
          <div className=" col-span-4 md:col-span-3 md:w-[322px] md:h-[430px] rounded-2xl shadow-2xl">
            <img
              src={selectedImages[0]}
              alt=""
              className=" w-full h-full object-cover rounded-2xl"
            />
          </div>
          <div className="col-span-6 md:col-span-4 grid gap-3 md:gap-6 grid-cols-5 h-full">
            <div className=" col-span-2 rounded-2xl h-full md:w-[160px] md:h-[204px]  shadow-2xl">
              <img
                src={selectedImages[1]}
                alt=""
                className=" w-full h-full object-cover rounded-2xl"
              />
            </div>
            <div className="col-span-3 rounded-2xl h-full md:w-[256px] md:h-[204px] shadow-2xl">
              <img
                src={selectedImages[2]}
                alt=""
                className=" w-full h-full object-cover rounded-2xl"
              />
            </div>
            <div className="md:w-[443px] col-span-5 h-full rounded-2xl md:h-[190px] shadow-2xl">
              <img
               src={selectedImages[3]}
                alt=""
                className=" w-full h-full object-cover rounded-2xl"
              />
            </div>
          </div>
          <div className="col-span-3 sm:aspect-[3/4] md:w-[322px] md:h-[430px] rounded-2xl hidden md:block  shadow-2xl">
            <img
             src={selectedImages[4]}
              alt=""
              className=" w-full h-full object-cover rounded-2xl "
            />
          </div>
        </div> */}
           <div className=" w-full h-auto grid grid-cols-10 bg-[#553833] px-4 py-5 gap-4 lg:px-14 lg:py-10 lg:gap-10">
          <div className=" col-span-4 md:col-span-3 aspect-[3/4]  rounded-2xl shadow-2xl">
            <img
              src={selectedImages[0]}
              alt=""
              className=" w-full h-full object-cover rounded-2xl"
            />
          </div>
          <div className=" col-span-6 md:col-span-4 grid gap-3 md:gap-6 grid-cols-5 h-full">
            <div className=" col-span-2  rounded-2xl h-full  shadow-2xl">
              <img
                src={selectedImages[1]}
                alt=""
                className=" w-full h-full object-cover rounded-2xl"
              />
            </div>
            <div className=" col-span-3  rounded-2xl h-full  shadow-2xl">
              <img
                src={selectedImages[2]}
                alt=""
                className=" w-full h-full object-cover rounded-2xl"
              />
            </div>
            <div className=" col-span-5  rounded-2xl h-full  shadow-2xl">
              <img
                src={selectedImages[3]}
                alt=""
                className=" w-full h-full object-cover rounded-2xl"
              />
            </div>
          </div>
          <div className=" col-span-3 aspet-[3/4]  rounded-2xl hidden md:block  shadow-2xl">
            <img
              src={selectedImages[4]}
              alt=""
              className=" w-full h-full object-cover rounded-2xl "
            />
          </div>
        </div>
        <div className=" bg-white py-6 md:py-10 text-center uppercase text-[#553833] text-xs md:text-2xl">
          <div
            className=" px-4 w-[90%] lg:px-32 py-2 lg:py-4 max-w-[1024px]"
            style={{
              backgroundImage: `url(${FellText})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundPosition: "center",
            }}
          >
            the makeup that we do enhances your true beauty.
          </div>
        </div>
        <div className=" p-5 py-10 text-sm md:text-lg">
          <p className=" text-white font-[Poppins] text-center flex justify-center md:max-w-[80%] mx-auto">
            Feel the Wellness, located at Gandhinagar, Ahmedabad, offers
            professional bridal makeup services to make your special day even
            more special. Our team of skilled and experienced makeup artists
            work to enhance your natural beauty and make you feel confident and
            beautiful on your wedding day.
          </p>
        </div>

        {/*  child & sub category */}

        <div className=" p-4 gap-7 mt-4  w-full flex flex-col md:gap-5 overflow-x-auto py-0 mb-6">
          <div className="w-fit max-w-md min-w-fit mx-auto rounded-lg box-border flex gap-1">
            {subCategoryData?.map((item) => (
              <button
                className={` h-[35px] flex text-white items-center justify-center border border-white outline-none bg-transparent cursor-pointer min-w-max w-[90px] text-center rounded-md px-[10px]  ${
                  selectedSubCategory == item
                    ? " !bg-[#CAAC5C] !text-black !border-[#CAAC5C]"
                    : ""
                }`}
                onClick={() => setSelectedSubCategory(item)}
              >
                {item?.name}
              </button>
            ))}
          </div>
        </div>
        <div className=" px-4 w-full gap-7 mt-4 flex flex-col md:gap-5 overflow-x-auto mb-6">
          {childCategoryData?.length > 0 && (
            <div className="  w-fit flex  mx-auto max-w-md min-w-fit rounded-lg box-border gap-1">
              {childCategoryData?.map((item) => (
                
                <button
                  className={` h-[35px] flex items-center justify-center border border-white outline-none text-white bg-transparent cursor-pointer min-w-max text-center rounded-md px-[10px]
                        md:hover:bg-white md:hover:text-[#000] ${
                          selectedChildCategory.id == item.id
                            ? "!bg-white !text-[#000]"
                            : ""
                        }`}
                  onClick={() => {
                    setSelectedChildCategory(item);
                    console.log(item);
                  }}
                >
                  {item?.name}
                </button>
              ))}
            </div>
          )}
        </div>

        <div className=" p-4 font-[Poppins] pb-20">
          <div className=" flex flex-col gap-8 text-white max-w-screen-md mx-auto">
            {filteredServices?.map((item, index) => (
              <div
                className=" w-full flex gap-4 md:gap-6 items-center"
                key={index}
              >
                <div className=" grow shrink flex flex-col gap-2">
                  <h2 className=" !font-semibold text-[15px] md:text-[18px] cursor-pointer "
                    onClick={() => {
                      setShowDescription((prev) => {
                        if (prev.includes(index)) {
                          return prev.filter((i) => i !== index);
                        } else {
                          return [...prev, index];
                        }
                      });
                    }}
                  >
                    {item?.service_name}
                  </h2>
                  {/* <p className=" font-normal md:font-light text-[13px] md:text-[15px]">
                    {item?.description}
                  </p> */}
                  {showDescription.includes(index) && (
                    <p className=" font-normal md:font-light text-[13px] md:text-[15px]">
                      {item?.description}
                    </p>
                  )}
                </div>
                <div className=" grow-0 shrink-0 w-[90px] md:w-[110px] h-full">
                  <div className=" flex flex-col gap-1 justify-center items-center h-full">
                    <p>&#8377; {item?.price}/-</p>
                    <button
                      className=" bg-white rounded-full text-xs md:text-sm text-[#553833] px-3 py-1"
                      onClick={() => {
                        if (bookedServiceData.some((i) => i.id === item?.id)) {
                          toast.error("Service already booked");
                          return;
                        }
                        setBookedServiceData([...bookedServiceData, item]);
                      }}
                    >
                      {bookedServiceData.some((i) => i.id === item?.id) ? (
                        <i>Booked</i>
                      ) : (
                        "Book now"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePage;
