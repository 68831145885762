import React from "react";
import Pill from "../../../assets/images/feel-Philo.png";
import { motion, AnimatePresence } from "framer-motion";

const WellnessSpa = () => {
  return (
    <>
       <AnimatePresence mode="wait">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 1 }}
        >
          <div
            className="bg-[#553833] text-white flex flex-row md:flex-row justify-around items-center mb-0 p-8 md:p-12 space-x-4 md:space-y-0"
            style={{ fontFamily: "Bulter" }}
          >
            <motion.div
              className="text-center"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3 }}
            >
              <p className="text-sm text-[#FCF1FD]">Massage</p>
              <h2 className="text-xl md:text-3xl text-[#FCF1FD]">Wellness</h2>
              <p className="text-sm text-[#FCF1FD]">Therapy</p>
            </motion.div>

            <motion.div
              className="text-center flex flex-col gap-1"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <p className="text-sm text-[#FCF1FD]">Beauty</p>
              <p className="text-xl md:text-3xl text-[#FCF1FD]">Glamour</p>
              <p className="text-sm text-[#FCF1FD]">Skin</p>
            </motion.div>

            <motion.div
              className="text-center"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.7 }}
            >
              <p className="text-xs text-[#FCF1FD]">Health</p>
              <h2 className="text-2xl md:text-3xl text-[#FCF1FD]">Fitness</h2>
              <p className="text-xs text-[#FCF1FD]">Body</p>
            </motion.div>
          </div>

          <div className="bg-[#553833] text-white p-8 font-serif">
            {/* Middle Section */}
            <motion.div
              className="flex flex-col md:flex-row justify-center items-center"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.2, duration: 1 }}
            >
              <motion.div
                className="hidden md:block shadow-md"
                style={{ zIndex: "10000" }}
                initial={{ opacity: 0, rotate: -10 }}
                animate={{ opacity: 1, rotate: 0 }}
                transition={{ duration: 1 }}
              >
                <div className="bg-[#d2b48c] w-32 h-32 md:w-52 md:h-52">
                  <img src={Pill} alt="" className=" h-full w-full object-cover" />
                </div>
              </motion.div>

              <motion.div
                className="bg-white text-[#3d2c2a] p-8 pt-6 max-w-full md:max-w-3xl"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <h2 className="text-3xl mb-4 text-center font-[Bulter]">Our Philosophy</h2>
                <p
                  className="text-sm leading-relaxed text-center"
                  style={{ fontFamily: "Poppins" }}
                >
                  Feel the real spirit of relaxation with innate and hearty vibes. Enjoy fresh crisp yogurts, exercise in a soothed ambiance that uplifts the vitality of life. Give yourself time for relaxation, absolute bliss, and enhance your look with us.
                </p>
              </motion.div>
            </motion.div>

            {/* Bottom Section */}
            <motion.div
              className="mt-16 text-center"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 1 }}
            >
              <h2
                className="text-2xl md:text-3xl mb-4 font-[abel] font-normal"
                style={{ letterSpacing: "4px" }}
              >
                Largest salon and spa in gandhinagar
              </h2>
              <p
                className="text-sm font-light leading-[28px]"
                style={{ fontFamily: "Poppins", letterSpacing: "1.5px" }}
              >
                Soul & Body has been a professional massage clinic since 2010! We specialize in classic
                <br className="hidden md:block" />
                Swedish massage for adults and kids, sports and medical massage treatment.
              </p>
            </motion.div>
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default WellnessSpa;
