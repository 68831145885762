import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar/Navbar'
import {BrowserRouter as Router , Routes, Route} from 'react-router-dom'
import ServicePage from './Components/services/ServicePage';
import Aboutus from './Components/About/Aboutus';
import Footer from './Components/Footer/Footer';
import Gallery from './Components/gallery/Gallery';
import Cart from './Components/cart/Cart';
import Contect from './Components/Contect/Contect'
import Blog from './Components/Blog/Blog';
import BlogPage from './Components/Blog/BlogPage';
import Home from './Components/Home/Home';

function App() {
  return (
   <>
   <Router>
   <Navbar/>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/service/:slug" element={<ServicePage/>}/>
      <Route path='/About' element={<Aboutus/>}/>
      <Route path='/Contect' element={<Contect/>}/>
      <Route path='/Blog' element={<Blog/>}/>
      <Route path='/Blog/:slug' element={<BlogPage/>}/>
      <Route path='/gallery' element={<Gallery/>}/>
      <Route path='/cart' element={<Cart/>}/>
    </Routes>
    <Footer/>
    </Router>
   </>
  );
}

export default App;
